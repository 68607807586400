<template>
  <div class="container" v-show="contenshow">
    <div style="width:100%;height:100%;">
      <div class="Mask" v-if="isMask"></div>
      <div class="lucky-wheel">
        <div class="lucky-title">
          <div class="price_title">訂單金額<span class="price">已滿￡{{priceShow}}</span></div>
          <div class="price_title_en">Order over<span class="price">￡{{priceShow}}</span></div>
        </div>
        <div class="lucky-tip">
          <div class="price_title">快來<span class="price">抽獎吧</span></div>
          <div class="price_title_en">Please spin the wheel</div>
        </div>
        <div class="box">
          <img :src="require('../../assets/image/bg_zp3.png')" class="images" width="100%" id="image"/>
          <LuckyWheel
              ref="LuckyWheel"
              class="luck-draw"
              width="6.875rem"
              height="6.875rem"
              :prizes="prizes"
              :default-style="defaultStyle"
              :default-config="defaultConfig"
              :blocks="blocks"
              :buttons="buttons"
              @start="startCallBack"
              @end="endCallBack"
              @stop="stopCallBack()"
          />
        </div>
        <!-- <div>
          <div class="socll_main">
            <img src="../../assets/image/trophy.png" alt="" class="trophy">
            <div class="socllDiv" style="margin-top:-25px" id="socllDiv">
              <div class="socllDiv_item">
                <ul>
                  <li v-for="(item,index) in jackpotList" :key="index" class="socllDiv_li">
                    <span class="socllDiv_mian">
                      <span class="socllDiv_text" style="margin-left:3px">
                        <span class="socllDiv_text_font">{{item.nickName}}</span> <br/> 
                        won <span  class="socllDiv_text_font">￡{{item.goodsPrice}}</span>,Congratulations
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="Terms">
           <div class="detail" @click="viewDetail">條款細則<span> Terms and Conditions</span></div>
        </div> -->
        <div style="text-align: center;width:100%;">
          <!-- <van-button type="warning"  @click="startCallBack" style="border:none;background: linear-gradient(90deg, #FFCE38, #F7A92E);width:30%; margin-right:50px;height:35px;border-radius:10px;z-index:1;">開始<br>Start</van-button>
          <van-button type="warning" :disabled="!stopbtn"  @click="stopCallBack"  style="border:none;background: linear-gradient(90deg, #64B046, #AEE845);width:30%; height:35px;border-radius:10px;z-index:1;">停止<br>Stop</van-button> -->
          <van-button class=" btn start_btn" @click="startCallBack"></van-button>
          <van-button :class="[stopbtn ? 'stop_btn' : 'stop_btn_disabled','btn']" @click="stopCallBack"></van-button>
        </div>
        <!-- <div class="rankDiv">
          <div class="rankDiv_img" style="position: absolute;top: -18px;left: 25%;">
            <img src="../../assets/image/Jackpot1.png" alt="" width="100%" height="100%">
          </div>
          <table class="rankDiv_table" cellspacing="0" cellpadding="0">
            <tr class="table_th" style="width:100%">
              <th class="borderRadiusL" style="width:40%;">User</th>
              <th style="width:30%">Amount</th>
              <th class="borderRadiusR" style="width:30%;">Time</th>
            </tr>
            <tr v-for="item in jackpotRank" :key="item.code" class="table_tr">
              <td style="width: auto !important;color:#B15824;word-wrap: break-word">{{item.nickName}}</td>
              <td style="width: auto !important;color:#FF9F28;">￡{{item.goodsPrice}}</td>
              <td style="width: auto !important;color:#808080;font-weight:100;word-wrap: break-word" class="table_time">{{parseTime(item.createTime)}}</td>
            </tr>
          </table>
        </div> -->

        <div class="rankDiv">
          <div class="rankDiv_img" style="position: absolute;top: -18px;left: 25%;">
            <img src="../../assets/image/Jackpot1.png" alt="" width="100%" height="100%">
          </div>
          <div class="rankmain">
            <div class="rankmain-top">
              <div style="width:40%;">User</div>
              <div style="width:30%">Amount</div>
              <div  style="width:30%;">Time</div>
            </div>
            <div class="rankmain-top-item" id="socllDiv">
              <div class="rankmain-socll table_tr" v-for="item in jackpotRank" :key="item.code">
                <div style="width: auto !important;color:#B15824;word-wrap: break-word;width:40%;">{{item.nickName}}</div>
                <div style="width: auto !important;color:#FF9F28;width:30%;">￡{{item.goodsPrice}}</div>
                <div style="width: auto !important;color:#808080;font-weight:100;word-wrap: break-word;width:30%" class="table_time">{{parseTime(item.createTime)}}</div>
              </div>
            </div>
          </div>
        </div>

        <div v-show="detailStatus" class="deatilTip">
          <div class="tip">
            <!-- <div class="close" @click="detailStatus = false"></div> -->
            <!-- <div class="tiptitle">幸運輪盤 條款及細則<br/><span>Lucky Wheel Terms and Conditions</span></div> -->
            <div class="tip-content">
              <p style="text-align: center;font-size:16px;margin-bottom:10px">幸運輪盤 條款及細則<br/><span>Lucky Wheel Terms and Conditions</span></p>
              <p>1、客戶可以因應幸運輪盤上的獎品格獲得相對應的獎品。<br/>
                    Players will win the prize corresponding to the prize stated on that segment on the Wheel.
              </p>
              <p>2、獎品不可轉讓或兌換現金。<br/>
                    All prizes for all winners are non-transferable and no cash alternative will be offered.
              </p>
              <p>3、所有獎品視乎供應情況而定，Pacific Foods有權在沒有通知的情況下取消、替代、更改獎品。<br/>
                    The prizes are subjected to availability. Pacific Foods reserves the right to hold void, cancel, suspend, substitute, or amend the prizes whenever it desires without giving notice. 
              </p>
              <p>4、Pacific Food 擁有最終決定權並不得進行任何交涉和爭辯。<br/>
                    The decision of Pacific Foods regarding any aspect of the Lucky Wheel will be final and binding. No correspondence will be entered into about it. 
              </p>
              <p>5、透過參加幸運輪盤，參加者被視為已同意此條款和細則。 Pacific Foods有權隨時修改本條款和細則而不作另行通知，任何修訂會即時生效。<br/>
                   Participants are deemed to have accepted and agreed to be bound by these terms and conditions upon entry and Pacific Foods reserves the right to amend these terms and conditions at any time. Any such revised terms and conditions shall have effect immediately.
              </p>
              <p>6、任何一名參加者如違反本條款和細則將會被取消資格。<br/>
                   Pacific Foods reserves the right to refuse entry or refuse to award any of the prizes to anyone in breach of these terms and conditions.
              </p>
              <p>7、如因技術問題（包括手機、電腦或網絡的技術問題）而引至參加者未能完成抽獎，Pacific Foods概不負責。<br/>
                    Pacific Foods accepts no responsibility for entries not successfully completed due to a technical fault, technical malfunction, computer hardware or software failure, satellite, network, or server of any kind whatsoever.
              </p>
              <p>8、參與本次幸運輪盤即表示參加者同意所提供的任何個人信息可供Pacific Foods保留、儲存並用於管理本次幸運輪盤活動。 Pacific Foods會採取所有必要的預防保護措施保護參加者的數據，詳情可以參考網站的個人隱私和數據處理政策。<br/>
                    Personal data supplied during the course of the Lucky Wheel will only be processed as set out in the privacy policy of the website.
              </p>
              <p>9、此等條款及細則的中英文版本如有歧義或不一致，一概以英文版為準。<br/>
                    If there is any conflict or inconsistency between the Chinese and English version, the English shall be the governing and prevailing version.
              </p>
              <div class="toast-confirm">
                <div @click="detailStatus = false">確認<br>Confirm</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main">
        <div class="toast"  v-if="endstatus">
          <div class="toast-container">
            <!-- <img :src="toastIcon" class="toast-picture" /> -->
            <div style="width:50px;height:50px;margin:0 auto;margin-top: 25px;">
              <img src="@/assets/image/icon_gx.png" alt="" width="100%">
            </div>
            <div class="close" @click="closeToast()"></div>
            <div class="toast-title"> 恭喜您獲得了{{ prize.goodsName }} <span v-show="prize.prizeNum > 1">x{{prize.prizeNum}}</span><br> 
              <span style="font-size:15px;">Congratulations !! <br> You won {{ prize.goodsNameEn }} <span v-show="prize.prizeNum > 1">x {{prize.prizeNum}}</span></span>
            </div>
            <div class="toast-price"> 價值(Value)￡{{ Math.abs(prize.price).toFixed(2) }} <span v-show="prize.prizeNum > 1">x{{prize.prizeNum}}</span></div>
            <div class="toast-btn">
              <div class="toast-cancel" @click="closeToast">關閉<br>Close</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const CIRCLE_ANGLE = 360

const config = {
  // 总旋转时间
  duration: 4000,
  // 旋转圈数
  circle: 8,
  mode: 'ease-in-out'
}
import {getCarousel,saveCarousel,stopCarousel,checkReally,jackpotList,jackpotRankList} from "@/api/turntableactivity"
export default {
  name:'turntableactivity',
  data() {
    return {
        // count: 1, // 剩余抽奖次数
        prizeList: [],
        prize:{},
        endstatus:false,
        contenshow:false,
        defaultStyle: {
          // fontColor: '#d64737',
          fontSize: '12px',
          wordWrap:false,
          lengthLimit:'100%'
        },
        defaultConfig: {
          speed: 5,
          accelerationTime:3000,
          decelerationTime: 7000,
          speedFunction:'cubic',
          textDeg:0
        },
        blocks: [
          { padding: '5px', background: '#fff' },
        ],
        buttons: [
          // { radius: '30px', background: '#fff' },
          {
            radius: '50px',
            imgs: [{ src: require('../../assets/image/GO.png'), width: '100%', top: '-130%' }],
          },
        ],
        prizes:[],
        priceShow:0,
        stopbtn:false,
        colorList:['#F2A2A5','#7D9F28','#EAB704','#88F4EE','#D70502','#E44466','#B7E151','#DA415B','#EFBD1C','#248E7D','#7CC4DE','#A2F3EC','#214461','#0B694E','#DC4F84'],
        detailStatus:true,
        isMask:true,
        orderId:'',
        prizecode:'',
        flag: null,
        jackpotCode:false,
        // jackpotList:[],
        jackpotRank:[],
        time:null,
        area:null,
        liHeight:null
    }
  },
  destroyed() {
    clearInterval(this.time);
  },
  created() {
    this.orderPrice = this.$route.query.price*1
    this.orderId = this.$route.query.orderId
    this.flag = this.$route.query.flag
    // this.getJackpotList()
    this.getJackpotRankList()
  },
  mounted () {
    checkReally({orderAmount:this.orderPrice,orderNum:this.orderId}).then(res => {
      let result = res.data;
      if(result) {
        this.jackpotCode = false;
        if(res.data.grade == '2') {
          this.jackpotCode = true;
        }
        this.prizecode = res.data.code;
        this.initPrizeList();
      }else {
        this.$router.push({path:'/review',query:{orderNum:this.orderId}})
      }
    })
    
  },
  methods: {
    initPrizeList() {
      let prams = {
        orderAmount:this.orderPrice
      }
      getCarousel(prams).then(res => {
        if(res.data.list.length > 0){
          this.prizeList = res.data.list;
          this.priceShow = res.data.list[0].levelOneAmount*1;
          this.getPrizesList()
        }else {
          this.$router.push({path:'/review',query:{orderNum:this.orderId}})
        }
        this.contenshow = true;
        this.$nextTick(()=> {
            setTimeout(()=> {
              this.isMask = false;
            },1000)
        })
        setTimeout(()=> {
          this.scrollDiv()
        },1000)
      })
    },
    getPrizesList () {
        const prizes = []
        let copylist =  JSON.parse(JSON.stringify(this.prizeList))
        let jackpotlist = copylist.filter(item => item.grade == 2)
        let otherlist = copylist.filter(x => x.grade != 2)
        let percentage = '';
        let imgpercentage = ''
        let imgwight = ''
        if(this.prizeList.length >= 16) {
          percentage = '-90%'
          imgpercentage = '-90%'
          imgwight = '80%'
        }else if(this.prizeList.length >= 13 &&  this.prizeList.length < 16) {
          percentage = '-90%'
          imgpercentage = '-75%'
          imgwight = '80%'
        }else if(this.prizeList.length >= 11 && this.prizeList.length < 13){
          percentage = '-70%'
          imgpercentage = '-65%'
          imgwight = '70%'
        }else if (this.prizeList.length >= 9 && this.prizeList.length < 11) {
          percentage = '-60%'
          imgpercentage = '-55%'
          imgwight = '70%'
        }else if (this.prizeList.length >= 7 && this.prizeList.length < 9) {
          percentage = '-40%'
          imgpercentage = '-40%'
          imgwight = '70%'
        }else if (this.prizeList.length < 7 &&  this.prizeList.length  >= 5) {
          percentage = '-40%'
          imgpercentage = '-35%'
          imgwight = '50%'
        }else if(this.prizeList.length < 5 && this.prizeList.length  >= 4){
          percentage = '-20%'
          imgpercentage = '-20%'
          imgwight = '25%'
        }
        if(otherlist.length > 0) {
            otherlist.forEach((item, index) => {
            if(otherlist.length > this.colorList.length) {
              let len = Math.ceil(otherlist.length / this.colorList.length)
              for(let i = 0 ;i < len; i++) {
                this.colorList = this.colorList.concat(this.colorList)
              }
            }
            prizes.push({
              name: item.name,
              nameEn: item.nameEn,
              goodsName: item.goodsName,
              goodsNameEn:item.goodsNameEn,
              showUnit: item.showUnit,
              prizeNum: item.prizeNum,
              background: this.colorList[index],
              fonts:  [{ text: item.showUnit, top: '-0.26rem' ,fontColor: '#fff',fontSize:'0.4rem',fontWeight:600,left:percentage}],
              // imgs : [{ src: item.cover, width: '35%', top: '40%' }],
              code: item.code,
              price: item.price,
              grade:item.grade
            })
          })
        }
        if(jackpotlist.length > 0) {
          jackpotlist.forEach(v => {
            prizes.push({
              name: v.name,
              nameEn: v.nameEn,
              goodsName: v.goodsName,
              goodsNameEn:v.goodsNameEn,
              showUnit: v.showUnit,
              prizeNum: v.prizeNum,
              background: '#fff',
              fonts:  [{ text: v.unit + v.price, top: '-0.35rem' ,left:percentage,fontColor: '#D70502',fontSize:'0.37rem',fontWeight:600}],
              imgs : [{ src: require('../../assets/image/jackpot.png'), width: imgwight, top: '0.125rem' ,left:imgpercentage}],
              price: v.price,
              grade:v.grade
            })
          })
        }
        this.prizes = prizes
    },
    startCallBack () {
      this.$refs.LuckyWheel.play()
      this.stopbtn = true;
    },
    stopCallBack () {
      var that = this;
      setTimeout(()=> {
        that.prizes.filter(function(x,index) {
          if(that.jackpotCode == true) {
            if(x.grade == '2') {
               that.$refs.LuckyWheel.stop(index)
            }
          }else {
            if(x.code == that.prizecode) {
              that.$refs.LuckyWheel.stop(index)
            }
          }
        })
      },1000)
      stopCarousel({orderAmount:this.orderPrice,orderNum:this.orderId}).then(res => {
      })
    },
    endCallBack (prize) {
      this.prize = prize;
      this.endstatus = true;
      this.stopbtn = false;
    },
    //关闭弹窗
    closeToast() {
      this.endstatus = false;
      this.back()
    },
    back() {
      if(this.flag && this.flag == '1') {
         this.$router.push('/order')
      }else {
        this.$router.push({path:'/review',query:{orderNum:this.orderId}})
      }
      this.$store.dispatch('getWallet')
    },
    viewDetail() {
      this.detailStatus = !this.detailStatus;
    },
    // getJackpotList() {
    //   jackpotList().then(res => {
    //     if(res.code == 200) {
    //       this.jackpotList = res.data.list;
    //     }
    //   })
    // },
    getJackpotRankList() {
      jackpotRankList().then(res => {
        if(res.code == 200) {
          this.jackpotRank = res.data.list;
        }
      })
    },
    scrollDiv(){
      if(this.jackpotRank.length > 5) {
        this.area = document.getElementById('socllDiv');
        this.liHeight = document.getElementsByClassName('rankmain-socll')[0] ? document.getElementsByClassName('rankmain-socll')[0].offsetHeight : 0;
        this.area.innerHTML += this.area.innerHTML;
        this.area.scrollTop = 0;
        setTimeout(this.startMove, 2000)
      }
    },
    startMove() {
        this.area.scrollTop++;
        this.time = setInterval(this.scrollUp, 20);
    },
    scrollUp() {
          if (this.area.scrollTop % this.liHeight === 0) {
              clearInterval(this.time);
              setTimeout(this.startMove, 500);
          } else {
            let old = JSON.parse(JSON.stringify(this.area.scrollTop));
            this.area.scrollTop = this.area.scrollTop + 1;
            if(old == this.area.scrollTop){
              this.area.scrollTop = 0;
            }
          }
          if (this.area.scrollTop >= this.area.scrollHeight / 2) {
              this.area.scrollTop = 0;
          }
      }
  }
};
</script>
<style scoped>

.container {
  width: 100%;
  height: 100%;
}
.lucky-wheel {
  width: 750px;
  /* height: 750px; */
  padding-top: 40px;
  position: relative;
}
.lucky-title {
  width: 100%;
  max-height: 150px;
  /* line-height: 150px; */
  /* background: url("../../assets/image/write.png") no-repeat center; */
  /* background-size: 100%; */
  margin: 0 auto;
  text-align: center;
  font-size: 30px;
  z-index: 1000;
  position: relative;
}
.lucky-tip {
  width: 100%;
  max-height: 150px;
  /* line-height: 80px; */
  /* background: url("../../assets/image/write1.png") no-repeat center; */
  /* background-size: 100%; */
  margin: 10px auto;
  text-align: center;
  z-index: 1000;
  position: relative;
  font-size: 30px;
}
.box {
  position: relative;
  max-width: 750px;
  max-height: 1005px;
  text-align: center;
  top: -40px;
}
/* .images {
  width: 750px;
  text-align: center;
} */
.main {
  position: relative;
  width: 100%;
  /* min-height: 230px; */
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
}

.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 20000;
  transform: translate(-50%, -50%);
  width: 600px;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  background-color: rgb(252, 244, 224);
}
.toast-container {
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px dotted #fccc6e;
}
.toast-picture {
  position: absolute;
  top: -200px;
  left: -50px;
  width: 600px;
  height: 274px;
}
.toast-title {
  padding: 20px 0;
  font-size: 18px;
  color: #fc7939;
  text-align: center;
}
.toast-price {
  /* padding: 20px 0; */
  font-size: 20px;
  color: #fc7939;
  text-align: center;
}
.toast-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.toast-btn div {
  background-image: -moz-linear-gradient(
    -18deg,
    rgb(242, 148, 85) 0%,
    rgb(252, 124, 88) 51%,
    rgb(252, 124, 88) 99%
  );

  background-image: -ms-linear-gradient(
    -18deg,
    rgb(242, 148, 85) 0%,
    rgb(252, 124, 88) 51%,
    rgb(252, 124, 88) 99%
  );
  background-image: -webkit-linear-gradient(
    -18deg,
    rgb(242, 148, 85) 0%,
    rgb(252, 124, 88) 51%,
    rgb(252, 124, 88) 99%
  );
  box-shadow: 0px 4px 0px 0px rgba(174, 34, 5, 0.7);
  min-width: 200px;
  height:90px;
  border-radius: 44px;
  text-align: center;
  line-height: 45px;
  color: #fff;
  font-size: 16px;
}
.close {
  position: absolute;
  top: -35px;
  right: -35px;
  width: 64px;
  height: 64px;
  background: url("../../assets/image/close_store.png") no-repeat center top;
  background-size: 100%;
}
.luck-draw {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.van-button {
  background: linear-gradient(90deg, #64B046, #AEE845);
  color: #fff;
}
.price_title {
  font-weight: 800;
  color: #fff;
  text-shadow: 2px 5px 1px #AEE845,
              2px 5px 1px #AEE845,
              2px 5px 1px #AEE845,
              2px 5px 1px #AEE845,
              2px 5px 1px #AEE845,
              2px 5px 1px #AEE845;
}
.price_title_en {
  font-weight: 800;
  color: #fff;
  font-size: 18px;
}
.price{
  font-weight: 800;
  color: #fff;
  text-shadow: 5px 8px 2px #64B046,
              2px 5px 2px #64B046,
              2px 5px 2px #64B046,
              2px 5px 2px #64B046,
              2px 5px 2px #64B046,
              2px 5px 2px #64B046;
}
.toast-tip {
  font-size: 12px;
  width: 100%;
  text-align: center;
  min-height: 80px;
  line-height: 40px;
  color: #fc7939;
  margin-bottom: 20px;
  margin-top: 10px;
}
.tip {
  position: relative;
  margin: 80px auto 0;
  width: 600px;
  border: 3px solid #fbc27f;
  background: #fff;
}
.tip-title {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 16px;
  color: #fff;
  background: #F7A92E;
  padding: 0.3125rem 0.625rem;
  text-align: center;
}
.tiptitle {
  text-align:center;
  font-size: 18px;
  color: #fff;
  padding: 10px 0;
  background: #F7A92E;
}
.tiptitle span {
    font-size: 14px;
}
.tip-content {
  padding: 50px 20px;
  font-size: 14px;
  color: #F7A92E ;
  line-height: 1.5;
  height: 850px;
  overflow-y: scroll;
}
.detail {
  font-size: .37rem;
  color:#FFA127;
  text-align: center;
  width: 100%;
  line-height: 32px;
  text-decoration: underline;
}
.deatilTip{
  position: absolute;
  top:12%;
  left: 12%;
  z-index: 1002;
}
.Mask {
  width: 100%;
  height: 100%;
  background: url('../../assets/image/bg_gwc.png') no-repeat;
  background-size: 100% 100%;
  z-index: 100000;
  position: absolute;
}
.toast-confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.toast-confirm div {
  background-image: linear-gradient(90deg, rgb(255, 206, 56), rgb(247, 169, 46));
  box-shadow: 0px 4px 0px 0px rgba(174, 34, 5, 0.7);
  min-width: 250px;
  max-height:90px;
  border-radius: 44px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  width: 30%;
  height: 92px;
  border: none;
}

.start_btn {
  background: url('../../assets/image/start_btn.png') no-repeat;
  background-size: 100% 100%;
}

.stop_btn {
  background: url('../../assets/image/stop_btn.png') no-repeat;
  background-size: 100% 100%;
  margin-left: 60px;
}
.start_btn_disabled {
  background: url('../../assets/image/start_btn_disabled.png') no-repeat;
  background-size: 100% 100%;
}
.stop_btn_disabled {
  background: url('../../assets/image/stop_btn_disabled.png') no-repeat;
  background-size: 100% 100%;
  margin-left: 60px;
}

.socllDiv {
  height: 69px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  background: rgba(255, 223, 202, 0.5);
  border-radius: 0.20rem;
}
.socllDiv_item {
  width: 85%;
  margin: 0 auto;
  padding: 0px 20px;
}
.socllDiv_li {
  height: 69px;
  line-height: 30px;
  padding: 0.093rem 0;
}
.socllDiv_mian {
  display: flex;
  justify-content:center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}
.trophy {
  width: 40px;
  height: 40px;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  left: 0.32rem;
  transform: translate(0,-50%);
}
.socllDiv_text {
  display: block;
  font-size: .36rem;
  color: #B15824;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.socllDiv_text_font {
  font-size: .36rem;
  color: #FF5215;
  font-weight: 500;
}
.Terms {
  margin-top: 30px;
  margin-bottom: 25px;
}
.rankDiv {
  width: 90%;
  margin: 100px auto;
  padding: 60px 40px;
  background: url('../../assets/image/rankbg.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.rankDiv_img {
  width: 340px;
  height: 60px;
}
.rankDiv_table {
  /* padding: 0px 20px; */
  box-sizing: border-box;
  table-layout:fixed;
  font-size: 12px;
  width: 100%;
  text-align: center;
}
.table_th {
  background: linear-gradient(0deg, #EB8A34 0%, #FA7F34 100%);
  opacity: 0.8;
  color: #fff;
  box-sizing: border-box;
  height: 49px;
  line-height: 49px;
  font-weight: 600;
  font-size: 0.43rem;
}
.table_tr {
  height: 100px;
  font-size: 0.38rem;
  font-weight: bold;
}
.table_time {
  font-size: 0.38rem;
}
.borderRadiusL {
  border-top-left-radius: 0.65rem;
  border-bottom-left-radius: 0.65rem;
}
.borderRadiusR {
  border-top-right-radius: 0.65rem;
  border-bottom-right-radius: 0.65rem;
}
.socll_main {
  position: relative;
  height: 69px;
  width: 90%;
  margin:0 auto;
}

.rankmain {
  font-size: 12px;
  width: 100%;
  text-align: center;
  position: relative;
}
.rankmain-top {
  width: 100%;
  height: 49px;
  background: linear-gradient(0deg, #EB8A34 0%, #FA7F34 100%);
  opacity: 0.8;
  border-radius: 24px;
  text-align: center;
  color: #fff;
  font-size: 0.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.rankmain-top-item{
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}
.rankmain-socll {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100px;
}
</style>

