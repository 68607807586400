import request from '@/utils/request'

//根据订单金额获取抽取的奖品
export function getCarousel(data) {
    return request({
      url: '/app/prize/list',
      method: 'post',
      data: data
    })
}

//保存用户抽奖结果
export function saveCarousel(data) {
    return request({
      url: '/app/prize/result',
      method: 'post',
      data: data
    })
}
//轮盘停止获取中奖结果
export function stopCarousel(data) {
  return request({
    url: '/app/prize/sure',
    method: 'post',
    data: data
  })
}

//检查用户是否真的可以抽到奖品
export function checkReally(data) {
  return request({
    url: '/app/prize/checkReally',
    method: 'post',
    data: data
  })
}

//jackpot list
export function jackpotList(data) {
  return request({
    url: '/app/prize/recentJackpot',
    method: 'post',
    data: data
  })
}

// jackpot rank
export function jackpotRankList(data) {
  return request({
    url: '/app/prize/rankingJackpot',
    method: 'post',
    data: data
  })
}